import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Stack,
	Select,
	useColorModeValue
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FileUpload from "../FileUpload";
export default function FileForm({ defaultValues, priceData, onFormSubmit, onFormCancel}) {
	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		control
	} = useForm({ defaultValues });
	const onSubmit = handleSubmit((data) => {
		onFormSubmit(data);
	});
	return (
		<Flex
			minH={"100vh"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Stack
				spacing={4}
				w={"full"}
				maxW={"md"}
				bg={useColorModeValue("white", "gray.700")}
				rounded={"xl"}
				boxShadow={"lg"}
				p={6}
				my={12}>
				<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
					{defaultValues ? <span>Editing file</span> : <span>Adding file</span>}
				</Heading>
				{	
					defaultValues ?
						(<FormControl id='url' isRequired isInvalid={errors.url}>
							<FormLabel>URL</FormLabel>
							<Input
								id='url'
								{...register("url", {
									required: true,
									minLength: 1,
								})}
							/>
							<FormErrorMessage>
								{errors.url && errors.url.message}
							</FormErrorMessage>                        
						</FormControl>)
						: 
						null
				}
				{	
					defaultValues ?
						<ExistingAttachedPrice errors={errors} register={register} priceData={priceData} existingPriceId={defaultValues.price.id} />
						: 
						<LeaseExclusivePrices errors={errors} register={register} priceData={priceData}/>
				}
				{
					defaultValues ?
						null
						:
						<FileUpload name="file"
							acceptedFileTypes=".zip"
							isRequired={true}
							placeholder="Beat file"
							control={control}>
							Beat file
						</FileUpload>
				}
				{
					defaultValues ?
						<FormControl id="fileRights" isRequired isInvalid={errors.fileRights}>
							<FormLabel>File Rights</FormLabel>
							<Select {...register("fileRights")}>
								<option value="LEASE"> LEASE</option>
								<option value="EXCLUSIVE"> EXCLUSIVE</option>
							</Select>
							<FormErrorMessage>
								{errors.fileRights && errors.fileRights.message}
							</FormErrorMessage>
						</FormControl>
						:
						null
				}


				<Stack spacing={6} direction={["column", "row"]}>
					{defaultValues ? 
						<Button
							bg={"red.400"}
							color={"white"}
							w='full'
							_hover={{
								bg: "red.500",
							}}
							onClick={() => onFormCancel()}>
                            Cancel
						</Button> 
						:
						null
					}   
					<Button
						bg={"blue.400"}
						color={"white"}
						w='full'
						_hover={{
							bg: "blue.500",
						}}
						isLoading={isSubmitting}
						onClick={() => onSubmit()}
					>
                            Submit
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}

function ExistingAttachedPrice({errors, register, existingPriceId, priceData}) {
	return <FormControl id='priceId' isRequired isInvalid={errors.priceId}>
		<FormLabel>Price</FormLabel>
		<Select {...register("priceId", {value: existingPriceId})}>
			<PriceOptions priceData={priceData}/>
		</Select>
		<FormErrorMessage>
			{errors.priceId && errors.priceId.message}
		</FormErrorMessage>
	</FormControl>;
}

function LeaseExclusivePrices({errors, register, priceData}) {
	return <>
		<FormControl id='leasePriceId' isRequired >
			<FormLabel>Lease Price</FormLabel>
			<Select {...register("leasePriceId")}>
				<PriceOptions priceData={priceData}/>
			</Select>
			<FormErrorMessage>
				{errors.leasePriceId && errors.leasePriceId.message}
			</FormErrorMessage>
		</FormControl>
		<FormControl id='exclusivePriceId' isRequired isInvalid={errors.exclusivePriceId}>
			<FormLabel>Exclusive Price</FormLabel>
			<Select {...register("exclusivePriceId")}>
				<PriceOptions priceData={priceData}/>
			</Select>
			<FormErrorMessage>
				{errors.exclusivePriceId && errors.exclusivePriceId.message}
			</FormErrorMessage>
		</FormControl>
	</>;
}

function PriceOptions({priceData}) {
	return priceData.map(price => <option key={price.id} value={price.id}>{price.amount}</option>);
}