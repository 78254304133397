import axios from "../lib/axios";
import { getAPIKey } from "../settings";

const getBeats = () => {
	return axios.get("/beats/all", {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const getBeat = (id) => {
	return axios.get("/beats/" + id + "/detail", {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const addBeat = (data) => {
	return axios.post("/beats", { ...data }, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const updateBeat = ({ id, ...data }) => {
	delete data.tags;
	return axios.put("/beats/" + id, { ...data }, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const getBeatFiles = (id) => {
	return axios.get("/beats/" + id + "/files", {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const attachBeatPreview = ({ id, data }) => {
	const formData = new FormData();
	formData.append("file", data);
	return axios.post("/beats/" + id + "/attach/preview",
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
				"x-api-key": getAPIKey()
			}
		});
};

const attachBeatThumbnail = ({ id, data }) => {
	const formData = new FormData();
	formData.append("file", data);
	return axios.post("/beats/" + id + "/attach/thumbnail",
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
				"x-api-key": getAPIKey()
			}
		},);
};

const attachBeatFiles = ({ id, data }) => {
	return axios.post( "/beats/" + id + "/attach/files", {fileIds: data}, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const attachBeatTags = ({ id, data }) => {
	
	return axios.post("/beats/" + id + "/attach/tags", {tagIds: data}, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const deleteBeat = (id) => {
	return axios.delete("/beats/" + id, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

export { getBeats, getBeat, addBeat, getBeatFiles, updateBeat, attachBeatPreview, attachBeatThumbnail, attachBeatTags, attachBeatFiles, deleteBeat };