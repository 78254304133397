import {
	Heading,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Stack,
	Button,
	Spinner, Box
} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
import { useNavigate } from "react-router-dom";
import {AiFillEdit, AiFillTags, AiFillFileImage, AiFillSound, AiOutlinePlus, AiFillFileAdd} from "react-icons/ai";
import { useQuery, useMutation, useQueryClient } from "react-query";
import NavBar from "../Navbar";
import { getBeats, deleteBeat } from "../../services/beatService";
import { DeleteBeatDisplay } from "./DeleteBeatDisplay";
function BeatDashboard() {
	return (
		<>
			<NavBar />
			<Heading as='h4' size='md'>
                Beats
			</Heading>
			<BeatsDisplay />
		</>
	);
}

function BeatsDisplay() {
	let navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isError, error, data } = useQuery(["beatsData"], () => getBeats(), {cacheTime: 300000});
	const { mutateAsync, isMutating: isSaving } = useMutation(deleteBeat, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("beatsData");
		}
	});
	const onDelete = (id) => {
		mutateAsync(id);
		navigate("/beats");
	};
	if (isLoading || isSaving) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="Beat loading failed"
			message={error.message} />;
	}
	return (
		<Box>
			<Button leftIcon={<AiOutlinePlus />} colorScheme='green' variant='outline' onClick={() => navigate("/add/beat/")}>
				Add
			</Button>
			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th>#</Th>
						<Th>Name</Th>
						<Th>Slug</Th>
						<Th>Status</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{data ? data.data.map((beat) =>
						<Tr key={beat.id}>
							<Td>{beat.id}</Td>
							<Td>{beat.name}</Td>
							<Td>{beat.slug}</Td>
							<Td>{beat.status}</Td>
							<Td>
								<Stack direction='row' spacing={4}>
									<Button leftIcon={<AiFillEdit />} colorScheme='blue' variant='outline' onClick={() => navigate(`/edit/beat/${beat.id}`)}>
										Edit
									</Button>
									<Button leftIcon={<AiFillTags />} colorScheme='blue' variant='outline' onClick={() => navigate(`/edit/beat/${beat.id}/attachtags`)}>
										Attach Tags
									</Button>
									<Button leftIcon={<AiFillSound />} colorScheme='blue' variant='outline' onClick={() => navigate(`/edit/beat/${beat.id}/attachpreview`)}>
										Attach Preview
									</Button>
									<Button leftIcon={<AiFillFileImage />} colorScheme='blue' variant='outline' onClick={() => navigate(`/edit/beat/${beat.id}/attachthumbnail`)}>
										Attach Thumbnail
									</Button>
									<Button leftIcon={<AiFillFileAdd />} colorScheme='blue' variant='outline' onClick={() => navigate(`/edit/beat/${beat.id}/attachfiles`)}>
										Attach Files
									</Button>
									<DeleteBeatDisplay onDelete={() => onDelete(beat.id)} />
								</Stack>
							</Td>
						</Tr>)
						: null}
				</Tbody>
				<Tfoot>
					<Tr>
						<Th>#</Th>
						<Th>Name</Th>
						<Th></Th>
					</Tr>
				</Tfoot>
			</Table>
		</Box>
	);
}

export default BeatDashboard;