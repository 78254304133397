import axios from "../lib/axios";
import { SERVER_URL, getAPIKey } from "../settings";

const getFile = (id) => {
	return axios.get("/files/" + id, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const searchFile = (searchTerm) => {
	return axios.post("/files/search", { searchTerm }, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const createFile = (data) => {
	const formData = new FormData();
	formData.append("file", data.file);
	formData.append("fileUploadMetadata", JSON.stringify(data));
	return axios.post("/files",
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
				"x-api-key": getAPIKey()
			}
		});
};

const updateFile = ({ id, data }) => {
	return axios.put("/files/" + id, data, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const deleteFile = ({ id }) => axios.delete(SERVER_URL + "/files/" + id, {
	headers: {
		"x-api-key": getAPIKey()
	}
});

export { getFile, searchFile, createFile, updateFile, deleteFile };