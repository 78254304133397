import {
	Container,
	Heading,
	Input,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Spinner,
	Button
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import NavBar from "./Navbar";
function HomeDashboard({submitAPIKey, isAPIKeySet}) {
    
	return (
		<>
			<NavBar />
			<Heading as='h4' size='md'>
                Home
			</Heading>
			<HomeDashboardDisplay submitAPIKey={submitAPIKey} isAPIKeySet={isAPIKeySet}/>
		</>
	);
}

function HomeDashboardDisplay({submitAPIKey, isAPIKeySet}) {
	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm({});

	const onSubmit = handleSubmit((data) => {
		submitAPIKey(data.apiKey);
	});

	if(isSubmitting) {
		return <Spinner/>;
	}

	if(!isAPIKeySet) {
		return (<Container>
			<FormControl id='apiKey' isRequired isInvalid={errors.apiKey}>
				<FormLabel>API Key</FormLabel>
				<Input
					id='apiKey'
					{...register("apiKey", {
						required: true,
						minLength: 1,
					})}
				/>
				<FormErrorMessage>
					{errors.apiKey && errors.apiKey.message}
				</FormErrorMessage>                        
			</FormControl>
			<Button
				bg={"blue.400"}
				color={"white"}
				w='full'
				_hover={{
					bg: "blue.500",
				}}
				isLoading={isSubmitting}
				onClick={() => onSubmit()}
			>
			Submit
			</Button>
		</Container>);
	} else {
		return (<Container>
			<Heading>
				Hello
			</Heading>
		</Container>);
	}

	

}

export default HomeDashboard;