import {useNavigate} from "react-router-dom";
import {Button, Spinner, Stack, Table, Tbody, Td, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import {ErrorDisplay} from "../ErrorDisplay";
import {AiFillEdit, AiFillFileAdd} from "react-icons/ai";
import {DeleteDisplay} from "../DeleteDisplay";

export function FileSearchResults({onDelete, isLoading, isError, data, error, onAttach}) {
	let navigate = useNavigate();

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="File search loading failed"
			message={error.message} />;
	}

	if (!data) {
		return null;
	}

	return (
		<Table variant="simple">
			<Thead>
				<Tr>
					<Th>#</Th>
					<Th>URL</Th>
					<Th>Rights</Th>
					<Th>Price</Th>
					<Th></Th>
					<Th></Th>
				</Tr>
			</Thead>
			<Tbody>
				{
					data.data.map(
						(file) =>
							<Tr key={file.id}>
								<Td>{file.id}</Td>
								<Td>{file.url}</Td>
								<Td>{file.fileRights}</Td>
								<Td>{file.price ? file.price.amount : null}</Td>
								<Td>
									<Stack direction="row" spacing={4}>
										{onDelete && !onAttach ?
											<Button leftIcon={<AiFillEdit />} colorScheme="blue" variant="outline" onClick={() => navigate(`/edit/file/${file.id}`)}>
												Edit
											</Button>
											:
											null
										}
										{onDelete && !onAttach ?
											<DeleteDisplay onDelete={() => onDelete(file.id)} resourceName="file" />	
											: 
											null}
										{onAttach ?
											<Button leftIcon={<AiFillFileAdd />} colorScheme="blue" variant="outline" onClick={() => onAttach(file)}>
												Add
											</Button>
											:
											null
										}
										
									</Stack>
								</Td>
							</Tr>
					)
				}
			</Tbody>
			<Tfoot>
				<Tr>
					<Th>#</Th>
					<Th>URL</Th>
					<Th>Rights</Th>
					<Th>Price</Th>
				</Tr>
			</Tfoot>
		</Table>
	);
}