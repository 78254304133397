import {
	Button,
	Flex,
	Heading, HStack,
	Stack, Text,
	useColorModeValue
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {FileSearch} from "../../file/FileSearch";
import {AiFillDelete} from "react-icons/ai";
export default function AttachBeatFilesForm({ defaultValues, onFormSubmit, onFormCancel, onAttach, attachedFiles, onRemove}) {
	const {
		handleSubmit,
		formState: {isSubmitting}
	} = useForm({ defaultValues });
	const onSubmit = handleSubmit((data) => {
		onFormSubmit(data.file);
	});
	return (
		<Flex
			flexDirection={"column"}
			minH={"100vh"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
				<span>Edit attached files for beat</span>
			</Heading>
			{attachedFiles.size > 0 ?
				<AttachedFiles attachedFiles={attachedFiles} onRemove={onRemove}/>
				:
				null
			}
			<FileSearch onAttach={onAttach}/>
			<Stack spacing={6} direction={["column", "row"]}>
				{defaultValues ?
					<Button
						bg={"red.400"}
						color={"white"}
						w='full'
						_hover={{
							bg: "red.500",
						}}
						onClick={() => onFormCancel()}>
                           Cancel
					</Button>
					:
					null
				}
				<Button
					bg={"blue.400"}
					color={"white"}
					w='full'
					_hover={{
						bg: "blue.500",
					}}
					isLoading={isSubmitting}
					onClick={() => onSubmit()}
					isActive={attachedFiles.length < 1}
				>
                       Submit
				</Button>
			</Stack>
		</Flex>
	);
}

function AttachedFiles({attachedFiles, onRemove}) {
	const files = [...attachedFiles].map((file) => <AttachedFile key={file.id} id={file.id} url={file.url} onRemove={onRemove}/>);
	return <section>
		<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
			<span>Attached Files</span>
		</Heading>
		{files}
	</section>;
}

function AttachedFile({id, url, onRemove}) {
	return (
		<HStack justify="center">
			<Text>{url}</Text>
			<Button leftIcon={<AiFillDelete />} colorScheme="red" variant="outline" onClick={() => onRemove(id)}>
				Delete
			</Button>)
		</HStack>);
}