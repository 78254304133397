import {
	Heading,
	Button,
	Flex} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import NavBar from "../Navbar";
import { FileSearch } from "./FileSearch";
function FileDashboard() {
	return (
		<>
			<NavBar />
			<Heading as="h4" size="md">
				Files
			</Heading>
			<FilesDisplay />
		</>
	);
}

function FilesDisplay() {
	let navigate = useNavigate();
	return (
		<Flex direction="column">
			<Button leftIcon={<AiOutlinePlus />} colorScheme="green" variant="outline" onClick={() => navigate("/add/file/")}>
				Add
			</Button>
			<FileSearch/>
		</Flex>);
}

export default FileDashboard;