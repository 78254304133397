import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	FormErrorMessage,
	Select,
	useColorModeValue
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
export default function BeatForm({ defaultValues, onFormSubmit, onFormCancel }) {
	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting }
	} = useForm({ defaultValues });
	const onSubmit = handleSubmit((data) => {
		onFormSubmit(data);
	});
	return (
		<Flex
			minH={"100vh"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Stack
				spacing={4}
				w={"full"}
				maxW={"md"}
				bg={useColorModeValue("white", "gray.700")}
				rounded={"xl"}
				boxShadow={"lg"}
				p={6}
				my={12}>
				<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
					{defaultValues ? <span>Editing beat: {defaultValues.name}</span> : <span>Adding beat</span>}
				</Heading>
				<FormControl id='name' isRequired isInvalid={errors.name}>
					<FormLabel>Name</FormLabel>
					<Input
						id='name'
						{...register("name", {
							required: true,
							minLength: 1,
						})}
					/>
					<FormErrorMessage>
						{errors.name && errors.name.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl id='slug' isRequired isInvalid={errors.slug}>
					<FormLabel>Slug</FormLabel>
					<Input
						id='slug'
						{...register("slug", {
							required: true,
							minLength: 1,
						})}
					/>
					<FormErrorMessage>
						{errors.slug && errors.slug.message}
					</FormErrorMessage>
				</FormControl>                
				<FormControl id='tempo' isRequired isInvalid={errors.tempo}>
					<FormLabel>Tempo</FormLabel>
					<Input
						id='tempo'
						{...register("tempo", {
							required: true,
							min: 1,
						})}
					/>
					<FormErrorMessage>
						{errors.tempo && errors.tempo.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl id='beatLength' isRequired isInvalid={errors.beatLength}>
					<FormLabel>Length</FormLabel>
					<Input
						id='beatLength'
						{...register("length", {
							required: true,
							minLength: 1,
						})}
					/>
					<FormErrorMessage>
						{errors.beatLength && errors.beatLength.message}
					</FormErrorMessage>
				</FormControl>
				<FormControl id='status' isRequired isInvalid={errors.status}>
					<FormLabel>Status</FormLabel>
					<Select {...register("status", {value: defaultValues ? defaultValues.beatStatus : "HIDDEN"})}>
						<option value="HIDDEN">Hidden</option>
						<option value="FOR_SALE">For Sale</option>
						<option value="SOLD">Sold</option>
					</Select>
					<FormErrorMessage>
						{errors.status && errors.status.message}
					</FormErrorMessage>
				</FormControl>
				<Stack spacing={6} direction={["column", "row"]}>
					{defaultValues ?
						<Button
							bg={"red.400"}
							color={"white"}
							w='full'
							_hover={{
								bg: "red.500",
							}}
							onClick={() => onFormCancel()}>
                            Cancel
						</Button>
						:
						null
					}
					<Button
						bg={"blue.400"}
						color={"white"}
						w='full'
						_hover={{
							bg: "blue.500",
						}}
						isLoading={isSubmitting}
						onClick={() => onSubmit()}
					>
                        Submit
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}