import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import BeatForm from "./BeatForm";
import NavBar from "../../Navbar";

import { getBeat, updateBeat } from "../../../services/beatService";
import {
	Spinner} from "@chakra-ui/react";
import { ErrorDisplay } from "../../ErrorDisplay";
export default function BeatEdit() {
	const params = useParams();
	return (
		<>
			<NavBar />
			<BeatEditData id={params.id} />
		</>
	);
}

function BeatEditData({ id }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isError, error, data } = useQuery(["beatData", id], () => getBeat(id));
	const { mutateAsync, isLoading: isMutating, isError: isMutationError, error: mutationError } = useMutation(updateBeat, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("beatData");
			await queryClient.invalidateQueries("beatsData");
		}
	});
	const onFormSubmit = async (formData) => {
		await mutateAsync({ id, ...formData });
		navigate("/beats");
	};
	const onFormCancel = () => {
		navigate("/beats");
	};

	if (isLoading || isMutating) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="Beat loading failed"
			message={error.message} />;
	}

	if (isMutationError) {
		return <ErrorDisplay
			title="Beat loading failed"
			message={mutationError.message} />;
	}

	return (
		<BeatForm
			defaultValues={data.data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}