import {
	Stack,
	Button,
	FormControl,
	FormLabel,
	Input
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteFile, searchFile} from "../../services/fileService";
import {FileSearchResults} from "./FileSearchResults";

export function FileSearch({onAttach}) {
	const queryClient = useQueryClient();
	const [searchTerm, setSearchTerm] = useState();
	const { isLoading, isError, error, data} = useQuery(["filesSearchData", searchTerm], () => searchFile(searchTerm), {
		enabled: !!searchTerm,
		retry: false
	});
	const { mutateAsync, isMutating: isSaving } = useMutation(deleteFile, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("filesSearchData");
		}
	});
	const {
		handleSubmit,
		register,
		formState: { isSubmitting },
	} = useForm();
	const onSubmit = handleSubmit( (data) => {
		setSearchTerm(data.searchTerm);
	});
	const onDelete = async (id) => {
		mutateAsync({id});
		await queryClient.invalidateQueries("filesSearchData");
	};
	return (
		<Stack>
			<FormControl>
				<FormLabel htmlFor="searchTerm">Search for file</FormLabel>
				<Input
					id="searchTerm"
					{...register("searchTerm")}
				/>
			</FormControl>
			<Button
				bg={"blue.400"}
				color={"white"}
				w="full"
				_hover={{
					bg: "blue.500",
				}}
				isLoading={isSubmitting}
				onClick={() => onSubmit()}
			>
                Search
			</Button>
			<FileSearchResults 
				searchTerm={searchTerm} 
				onDelete={onDelete}
				isLoading={isLoading || isSaving}
				isError={isError}
				data={data}
				error={error}
				onAttach={onAttach}
			/>
		</Stack>
	);
}