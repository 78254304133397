import {
	Button,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogCloseButton
} from "@chakra-ui/react";
export function DeleteDialogContent({ dialogTitle, dialogBody, cancelRef, isOpen, onClose, onDelete}) {
	return (
		<AlertDialog
			motionPreset='slideInBottom'
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isOpen={isOpen}
			isCentered
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader>{dialogTitle}</AlertDialogHeader>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					{dialogBody}
				</AlertDialogBody>
				<AlertDialogFooter>
					<Button ref={cancelRef} onClick={onClose}>
                        No
					</Button>
					<Button colorScheme='red' ml={3} onClick={() => {
						onDelete();
						onClose();
					}}>
                        Yes
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}