import {
	Button,
	Flex,
	Heading,
	Stack,
	useColorModeValue
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
export default function AttachTagForm({ tagData, beatData, onFormCancel, onFormSubmit}) {
	const mapTagToOptions = (tag) => ({ label: tag.name, value: tag.id });
	const [pickerItems, setPickerItems] = useState(tagData.map(mapTagToOptions));
	const [selectedItems, setSelectedItems] = useState(tagData.filter(tag => beatData.tags.includes(tag.name)).map(mapTagToOptions));
	const handleCreateItem = (item) => {
		setPickerItems((curr) => [...curr, item]);
		setSelectedItems((curr) => [...curr, item]);
	};

	const handleSelectedItemsChange = (selectedItems) => {
		if (selectedItems) {
			setSelectedItems(selectedItems);
		}
	};

	const onSubmit = () => onFormSubmit(selectedItems.map(item => item.value));

	return (
		<Flex
			minH={"100vh"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Stack
				spacing={4}
				w={"full"}
				maxW={"md"}
				bg={useColorModeValue("white", "gray.700")}
				rounded={"xl"}
				boxShadow={"lg"}
				p={6}
				my={12}>
				<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
					<span>Editing beat tags for {beatData.name}</span>
				</Heading>
				<CUIAutoComplete
					label="Choose tags for this beat"
					placeholder="Type a tag"
					onCreateItem={handleCreateItem}
					items={pickerItems}
					selectedItems={selectedItems}
					onSelectedItemsChange={(changes) =>
						handleSelectedItemsChange(changes.selectedItems)
					}
				/>
				<Stack spacing={6} direction={["column", "row"]}>
					<Button
						bg={"red.400"}
						color={"white"}
						w='full'
						_hover={{
							bg: "red.500",
						}}
						onClick={() => onFormCancel()}>
						Cancel
					</Button>
					<Button
						bg={"blue.400"}
						color={"white"}
						w="full"
						_hover={{
							bg: "blue.500",
						}}
						onClick={() => onSubmit()}
					>
						Submit
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}