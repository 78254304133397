import {useNavigate} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";

import NavBar from "../Navbar";

import {createFile} from "../../services/fileService";
import {
	Spinner
} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
import FileForm from "./FileForm";
import {getPrices} from "../../services/priceService";

export default function FileAdd() {
	return (
		<>
			<NavBar />
			<FileEditData/>
		</>
	);
}

function FileEditData() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading: isLoadingPrices, isError: isPriceError, error: priceError, data: priceData } = useQuery(["priceData"], () => getPrices());
	const { mutateAsync, isLoading: isMutating, isError, error} = useMutation(createFile, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("fileData");
			await queryClient.invalidateQueries("fileData");
		}
	});
	const onFormSubmit = async (formData) => {
		await mutateAsync(formData);
		navigate("/files");
	};
	const onFormCancel = () => {
		navigate("/files");
	};
	if (isError) {
		return <ErrorDisplay
			title="File adding failed"
			message={error.message} />;
	}
	if (isPriceError) {
		return <ErrorDisplay
			title="Price loading failed"
			message={priceError.message} />;
	}
	if (isMutating || isLoadingPrices) {
		return <Spinner/>;
	}
	return (
		<FileForm
			defaultValues={null}
			priceData={priceData.data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}