import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import AttachBeatFilesForm from "./AttachBeatFilesForm";
import NavBar from "../../Navbar";

import { getBeatFiles, attachBeatFiles } from "../../../services/beatService";
import {
	Spinner
} from "@chakra-ui/react";
import { ErrorDisplay } from "../../ErrorDisplay";
import {useState} from "react";
export default function AttachFilesEdit() {
	const params = useParams();
	return ( 
		<>
			<NavBar />
			<AttachBeatFilesData id={params.id} />
		</>
	);
}

function AttachBeatFilesData({ id }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [attachedFiles, setAttachedFiles] = useState(new Set());
	const { isLoading, isError, error, data } = useQuery(["beatData", id], () => getBeatFiles(id), {
		onSuccess: (data) => {
			setAttachedFiles(new Set(data.data.map((file) => {
				return file;
			})));
		}
	});
	const { mutateAsync, isLoading: isMutating, isError: isMutationError, error: mutationError } = useMutation(attachBeatFiles, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("beatData");
			await queryClient.invalidateQueries("beatsData");
		}
	});
	const onFormSubmit = async () => {
		await mutateAsync({id, data: [...attachedFiles].map((file) => file.id)});
		navigate(`/edit/beat/${id}/attachfiles/`);
	};
	const onFormCancel = () => {
		navigate("/beats");
	};
	const onAttach = (file) => {
		file.price = null;
		setAttachedFiles(new Set(attachedFiles).add(file));
	};
	const onRemove = (id) => {
		setAttachedFiles(new Set([...attachedFiles].filter((file) => file.id !== id)));
	};
	if (isError) {
		return <ErrorDisplay
			title="Beat loading failed"
			message={error.message} />;
	}

	if (isMutationError) {
		return <ErrorDisplay
			title="Beat file adding failed"
			message={mutationError.message} />;
	}

	if (isLoading || isMutating) {
		return <Spinner />;
	}
	return (
		<AttachBeatFilesForm
			defaultValues={data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel}
			onAttach={onAttach}
			onRemove={onRemove}
			attachedFiles={attachedFiles}
		/>
	);
}