import React, { useEffect, useState } from "react";
import {
	ChakraProvider,
	theme,
} from "@chakra-ui/react";
import {
	BrowserRouter,
	Routes,
	Route
} from "react-router-dom";
import TagDashboard from "./components/tag/TagDashboard";
import TagEdit from "./components/tag/TagEdit";
import BeatEdit from "./components/beat/metadata/BeatEdit";
import { QueryClient, QueryClientProvider } from "react-query";
import TagAdd from "./components/tag/TagAdd";
import BeatAdd from "./components/beat/metadata/BeatAdd";
import BeatDashboard from "./components/beat/BeatDashboard";
import AttachPreviewEdit from "./components/beat/attach-preview/AttachPreviewEdit";
import AttachThumbnailEdit from "./components/beat/attach-thumbnail/AttachThumbnailEdit";
import AttachTagEdit from "./components/beat/attach-tags/AttachTagEdit";
import FileDashboard from "./components/file/FileDashboard";
import FileEdit from "./components/file/FileEdit";
import FileAdd from "./components/file/FileAdd";
import AttachFilesEdit from "./components/beat/attach-files/AttachFilesEdit";
import PriceDashboard from "./components/price/PriceDashboard";
import PriceAdd from "./components/price/PriceAdd";
import PriceEdit from "./components/price/PriceEdit";
import HomeDashboard from "./components/HomeDashboard";
import { setAPIKey } from "./settings";

function App() {

	const [isAPIKeySet, setIsAPIKeySet] = useState(false);

	const submitAPIKey = (apiKey) => {
		setIsAPIKeySet(true);
		setAPIKey(apiKey);
		localStorage.setItem("apiKey", apiKey)	;
	};

	useEffect(() => {
		const apiKey = localStorage.getItem("apiKey");
		if(apiKey) {
			setAPIKey(apiKey);
			setIsAPIKeySet(true);
		}
	}, [isAPIKeySet]);

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 5*60*1000,
			}
		}
	});

	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider theme={theme}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<HomeDashboard submitAPIKey={submitAPIKey} isAPIKeySet={isAPIKeySet}/>} />
						<Route path="/tags" element={<TagDashboard/>} />
						<Route path="/beats" element={<BeatDashboard/>}/>
						<Route path="/files" element={<FileDashboard/>}/>
						<Route path="/prices" element={<PriceDashboard/>}/>

						<Route path="/add/tag" element={<TagAdd/>} />
						<Route path="/edit/tag/:id" element={<TagEdit/>} />

						<Route path="/add/file" element={<FileAdd/>} />
						<Route path="/edit/file/:id" element={<FileEdit/>} />

						<Route path="/add/price" element={<PriceAdd/>} />
						<Route path="/edit/price/:id" element={<PriceEdit/>} />



						<Route path="/add/beat" element={ <BeatAdd/>} />
						<Route path="/edit/beat/:id" element={<BeatEdit/>} />
						<Route path="/edit/beat/:id/attachpreview" element={<AttachPreviewEdit/>} />
						<Route path="/edit/beat/:id/attachthumbnail" element={<AttachThumbnailEdit/>} />
						<Route path="/edit/beat/:id/attachtags" element={<AttachTagEdit/>} />
						<Route path="/edit/beat/:id/attachfiles" element={<AttachFilesEdit/>} />
					</Routes>
				</BrowserRouter>
			</ChakraProvider>
		</QueryClientProvider>
	);
}

export default App;
