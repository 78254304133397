import {
	Heading,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableContainer,
	Stack,
	Button,
	Spinner} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
import { useNavigate } from "react-router-dom";
import { AiFillEdit, AiOutlinePlus} from "react-icons/ai";
import { useQuery} from "react-query";
import NavBar from "../Navbar";
import {getPrices} from "../../services/priceService";
function PriceDashboard() {
	return (
		<>
			<NavBar />
			<Heading as='h4' size='md'>
                Prices
			</Heading>
			<PricesDisplay />
		</>
	);
}

function PricesDisplay() {
	let navigate = useNavigate();
	const { isLoading, isError, error, data } = useQuery(["pricesData"], () => getPrices());
	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="Price loading failed"
			message={error.message} />;
	}
	return (
		<TableContainer>
			<Button leftIcon={<AiOutlinePlus />} colorScheme='green' variant='outline' onClick={() => navigate("/add/price/")}>
                    Add
			</Button>
			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th>#</Th>
						<Th>Amount</Th>
					</Tr>
				</Thead>
				<Tbody>
					{data ? data.data.map((price) =>
						<Tr key={price.id}>
							<Td>{price.id}</Td>
							<Td>${price.amount}</Td>
							<Td>
								<Stack direction='row' spacing={4}>
									<Button leftIcon={<AiFillEdit />} colorScheme='blue' variant='outline' onClick={() => navigate(`/edit/price/${price.id}`)}>
                                        Edit
									</Button>
								</Stack>
							</Td>
						</Tr>)
						: null}
				</Tbody>
				<Tfoot>
					<Tr>
						<Th>#</Th>
						<Th>Amount</Th>
					</Tr>
				</Tfoot>
			</Table>
		</TableContainer>);
}

export default PriceDashboard;