import axios from "../lib/axios";
import { getAPIKey } from "../settings";

const getTags = () => {
	return axios.get("/tags", {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const getTag = (id) => {
	return axios.get("/tags/" + id, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const addTag = (data) => {
	return axios.post("/tags", { ...data }, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const updateTag = ({ id, ...data }) => {
	return axios.put("/tags/" + id, { ...data }, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

const deleteTag = (id) => {
	return axios.delete( "/tags/" + id, {
		headers: {
			"x-api-key": getAPIKey()
		}
	});
};

export { getTags, getTag, addTag, updateTag, deleteTag };