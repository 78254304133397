import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Stack,
	useColorModeValue
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
export default function PriceForm({ defaultValues, onFormSubmit, onFormCancel}) {
	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm({ defaultValues });
	const onSubmit = handleSubmit((data) => {
		onFormSubmit(data);
	});
	return (
		<Flex
			minH={"100vh"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Stack
				spacing={4}
				w={"full"}
				maxW={"md"}
				bg={useColorModeValue("white", "gray.700")}
				rounded={"xl"}
				boxShadow={"lg"}
				p={6}
				my={12}>
				<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
					{defaultValues ? <span>Editing Price: {defaultValues.value}</span> : <span>Adding price</span>}
				</Heading>
				<FormControl id='amount' isRequired isInvalid={errors.slug}>
					<FormLabel>Amount</FormLabel>
					<Input
						id='amount'
						{...register("amount", {
							required: true,
							minLength: 1,
						})}
					/>
					<FormErrorMessage>
						{errors.amount && errors.amount.message}
					</FormErrorMessage>
				</FormControl>                                                                     
				<Stack spacing={6} direction={["column", "row"]}>
					{defaultValues ? 
						<Button
							bg={"red.400"}
							color={"white"}
							w='full'
							_hover={{
								bg: "red.500",
							}}
							onClick={() => onFormCancel()}>
                            Cancel
						</Button> 
						:
						null
					}   
					<Button
						bg={"blue.400"}
						color={"white"}
						w='full'
						_hover={{
							bg: "blue.500",
						}}
						isLoading={isSubmitting}
						onClick={() => onSubmit()}
					>
                            Submit
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}