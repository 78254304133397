import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import PriceForm from "./PriceForm";
import NavBar from "../Navbar";

import {
	Spinner} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
import {getPrice, updatePrice} from "../../services/priceService";
export default function PriceEdit() {
	const params = useParams();
	return (
		<>
			<NavBar />
			<PriceEditData id={params.id} />
		</>
	);
}

function PriceEditData({ id }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isError, error, data } = useQuery(["priceData", id], () => getPrice(id));
	const { mutateAsync, isMutating: isSaving } = useMutation(updatePrice, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("priceData");
			await queryClient.invalidateQueries("pricesData");
		}
	});
	const onFormSubmit = async (formData) => {
		await mutateAsync({ id, ...formData });
		navigate("/prices");
	};
	const onFormCancel = () => {
		navigate("/prices");
	};

	if (isLoading || isSaving) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="Price loading failed"
			message={error.message} />;
	}
	return (
		<PriceForm
			defaultValues={data.data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}