import {
	Heading,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableContainer,
	Stack,
	Button,
	Spinner} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
import { useNavigate } from "react-router-dom";
import { AiFillEdit, AiOutlinePlus} from "react-icons/ai";
import { useQuery, useMutation, useQueryClient } from "react-query";
import NavBar from "../Navbar";
import { getTags, deleteTag } from "../../services/tagService";
import { DeleteDisplay } from "../DeleteDisplay";
function TagDashboard() {
	return (
		<>
			<NavBar />
			<Heading as='h4' size='md'>
                Tags
			</Heading>
			<TagsDisplay />
		</>
	);
}

function TagsDisplay() {
	let navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isError, error, data } = useQuery(["tagsData"], () => getTags());
	const { mutateAsync, isMutating: isSaving } = useMutation(deleteTag, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("tagsData");
		}
	});
	const onDelete = (id) => mutateAsync(id);
	if (isLoading || isSaving) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="Tag loading failed"
			message={error.message} />;
	}
	return (
		<TableContainer>
			<Button leftIcon={<AiOutlinePlus />} colorScheme='green' variant='outline' onClick={() => navigate("/add/tag/")}>
                    Add
			</Button>
			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th>#</Th>
						<Th>Name</Th>
						<Th>Slug</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{data ? data.data.map((tag) =>
						<Tr key={tag.id}>
							<Td>{tag.id}</Td>
							<Td>{tag.name}</Td>
							<Td>{tag.slug}</Td>
							<Td>
								<Stack direction='row' spacing={4}>
									<Button leftIcon={<AiFillEdit />} colorScheme='blue' variant='outline' onClick={() => navigate(`/edit/tag/${tag.id}`)}>
                                        Edit
									</Button>
									<DeleteDisplay onDelete={() => onDelete(tag.id)} resourceName = "tag" />
								</Stack>
							</Td>
						</Tr>)
						: null}
				</Tbody>
				<Tfoot>
					<Tr>
						<Th>#</Th>
						<Th>Name</Th>
						<Th>Slug</Th>
						<Th></Th>
					</Tr>
				</Tfoot>
			</Table>
		</TableContainer>);
}

export default TagDashboard;