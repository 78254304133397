import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import AttachBeatThumbnailForm from "./AttachBeatThumbnailForm";
import NavBar from "../../Navbar";

import { getBeat, attachBeatThumbnail } from "../../../services/beatService";
import {
	Spinner
} from "@chakra-ui/react";
import { ErrorDisplay } from "../../ErrorDisplay";
export default function AttachThumbnailEdit() {
	const params = useParams();
	return ( 
		<>
			<NavBar />
			<AttachThumbnailEditData id={params.id} />
		</>
	);
}

function AttachThumbnailEditData({ id }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isError, error, data } = useQuery(["beatData", id], () => getBeat(id));
	const { mutateAsync, isLoading: isMutating, isError: isMutationError, error: mutationError } = useMutation(attachBeatThumbnail, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("beatData");
			await queryClient.invalidateQueries("beatsData");
		}
	});
	const onFormSubmit = async (data) => {
		await mutateAsync({id, data});
		navigate("/beats");
	};
	const onFormCancel = () => {
		navigate("/beats");
	};
	if (isError) {
		return <ErrorDisplay
			title="Beat loading failed"
			message={error.message} />;
	}

	if (isMutationError) {
		return <ErrorDisplay
			title="Thumbnail adding failed"
			message={mutationError.message} />;
	}

	if (isLoading || isMutating) {
		return <Spinner />;
	}
	return (
		<AttachBeatThumbnailForm
			defaultValues={data.data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}