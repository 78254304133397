import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import FileForm from "./FileForm";
import NavBar from "../Navbar";

import {
	Spinner} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
import {getFile, updateFile} from "../../services/fileService";
import {getPrices} from "../../services/priceService";
export default function FileEdit() {
	const params = useParams();
	return (
		<>
			<NavBar />
			<FileEditData id={params.id} />
		</>
	);
}

function FileEditData({ id }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isError, error, data } = useQuery(["fileData", id], () => getFile(id));
	const { isLoading: isLoadingPrices, isError: isPriceError, error: priceError, data: priceData } = useQuery(["priceData", id], () => getPrices());
	const { mutateAsync, isMutating: isSaving } = useMutation(updateFile, {
		onSuccess: async() => {
			await queryClient.invalidateQueries("fileData");
			await queryClient.invalidateQueries("filesSearchData");
		}
	});
	const onFormSubmit = async (data) => {
		data.price.id = parseInt(data.priceId);
		await mutateAsync({ id, data });
		navigate("/files");
	};
	const onFormCancel = () => {
		navigate("/files");
	};

	if (isLoading || isLoadingPrices || isSaving) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="Tag loading failed"
			message={error.message} />;
	}

	if(isPriceError) {
		return <ErrorDisplay
			title="Prices loading failed"
			message={priceError.message} />;
	}
	return (
		<FileForm
			defaultValues={data.data}
			priceData={priceData.data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}