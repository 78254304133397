import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	FormErrorMessage,
	useColorModeValue
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FileUpload from "../../FileUpload";
export default function AttachBeatPreviewForm({ defaultValues, onFormSubmit, onFormCancel }) {
	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		control
	} = useForm({ defaultValues });
	const onSubmit = handleSubmit((data) => {
		onFormSubmit(data.file);
	});
	const previewUrl = defaultValues;
	return (
		<Flex
			minH={"100vh"}
			justify={"center"}
			bg={useColorModeValue("gray.50", "gray.800")}>
			<Stack
				spacing={4}
				w={"full"}
				maxW={"md"}
				bg={useColorModeValue("white", "gray.700")}
				rounded={"xl"}
				boxShadow={"lg"}
				p={6}
				my={12}>
				<Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>

					{previewUrl ? (
						<Stack>
							<span>Editing beat thumnail</span>
							<img src={previewUrl.thumbnailURL}/>
						</Stack>
					) : 
						(<span>Adding beat thumbnail</span>)}

				</Heading>
				<FormControl id='thumbnailURL' isInvalid={errors.thumbnailURL}>
					<FormLabel>Preview Url</FormLabel>
					<Input
						id='thumbnailURL'
						{...register("thumbnailURL", {
						})}
					/>
					<FormErrorMessage>
						{errors.thumbnailURL && errors.thumbnailURL.message}
					</FormErrorMessage>
				</FormControl>             
				<FileUpload name="file"
					acceptedFileTypes="image/*"
					isRequired={true}
					placeholder="Preview file"
					control={control}>
                    Preview file
				</FileUpload>
				<Stack spacing={6} direction={["column", "row"]}>
					{defaultValues ?
						<Button
							bg={"red.400"}
							color={"white"}
							w='full'
							_hover={{
								bg: "red.500",
							}}
							onClick={() => onFormCancel()}>
                            Cancel
						</Button>
						:
						null
					}
					<Button
						bg={"blue.400"}
						color={"white"}
						w='full'
						_hover={{
							bg: "blue.500",
						}}
						isLoading={isSubmitting}
						onClick={() => onSubmit()}
					>
                        Submit
					</Button>
				</Stack>
			</Stack>
		</Flex>
	);
}