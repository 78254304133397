import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import PriceForm from "./PriceForm";
import NavBar from "../Navbar";

import {
	Spinner
} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
import {addPrice} from "../../services/priceService";
export default function PriceAdd() {
	return (
		<>
			<NavBar />
			<PriceEditData />
		</>
	);
}

function PriceEditData() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { mutateAsync, isLoading: isMutating, isError, error} = useMutation(addPrice, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("pricesData");
		}
	});
	const onFormSubmit = async (formData) => {
		await mutateAsync({...formData });
		navigate("/prices");
	};
	const onFormCancel = () => {
		navigate("/prices");
	};
	if (isError) {
		return <ErrorDisplay
			title="Price adding failed"
			message={error.message} />;
	}
	if (isMutating) {
		return <Spinner/>;
	}
	return (
		<PriceForm
			defaultValues={null}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}