import {
	Button,
	useDisclosure} from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";
import { useRef } from "react";
import { DeleteDialogContent } from "./DeleteDialogContent";
export function DeleteDisplay({onDelete, resourceName}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();

	return (
		<>
			<Button leftIcon={<AiFillDelete />} colorScheme='red' variant='outline' onClick={onOpen}>
                Delete
			</Button>
			<DeleteDialogContent 
				dialogTitle={`Delete ${resourceName}?`}
				dialogBody={`Are you sure you want to delete this ${resourceName}?`}
				cancelRef={cancelRef}
				isOpen={isOpen}
				onClose={onClose}
				onDelete={onDelete}
			/>
		</>
	);
}