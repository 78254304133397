import axios from "../lib/axios";
import { getAPIKey } from "../settings";

const getPrices = () => axios.get("/prices", {
	headers: {
		"x-api-key": getAPIKey()
	}
});

const getPrice = (id) => axios.get("/prices/" + id,  {
	headers: {
		"x-api-key": getAPIKey()
	}
});

const addPrice = (data) => axios.post("/prices", {...data},  {
	headers: {
		"x-api-key": getAPIKey()
	}
});

const updatePrice = ({id, ...data}) => axios.put("/prices/" + id, {...data},  {
	headers: {
		"x-api-key": getAPIKey()
	}
});

export {getPrices, getPrice, addPrice, updatePrice};