import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import TagForm from "./TagForm";
import NavBar from "../Navbar";

import { getTag, updateTag } from "../../services/tagService";
import {
	Spinner} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
export default function TagEdit() {
	const params = useParams();
	return (
		<>
			<NavBar />
			<TagEditData id={params.id} />
		</>
	);
}

function TagEditData({ id }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isError, error, data } = useQuery(["tagData", id], () => getTag(id));
	const { mutateAsync, isMutating: isSaving } = useMutation(updateTag, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("tagData");
			await queryClient.invalidateQueries("tagsData");
		}
	});
	const onFormSubmit = async (formData) => {
		await mutateAsync({ id, ...formData });
		navigate("/tags");
	};
	const onFormCancel = () => {
		navigate("/tags");
	};

	if (isLoading || isSaving) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorDisplay
			title="Tag loading failed"
			message={error.message} />;
	}
	return (
		<TagForm
			defaultValues={data.data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}