import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import BeatForm from "./BeatForm";
import NavBar from "../../Navbar";

import { addBeat } from "../../../services/beatService";
import {
	Spinner
} from "@chakra-ui/react";
import { ErrorDisplay } from "../../ErrorDisplay";
export default function BeatAdd() {
	return ( 
		<>
			<NavBar />
			<BeatEditData />
		</>
	);
}

function BeatEditData() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { mutateAsync, isLoading: isMutating, isError, error} = useMutation(addBeat, {
		onSuccess: () => {
			queryClient.invalidateQueries("beatsData");
		}
	});
	const onFormSubmit = async (formData) => {
		await mutateAsync({...formData });
		navigate("/beats");
	};
	const onFormCancel = () => {
		navigate("/beats");
	};
	if (isError) {
		return <ErrorDisplay
			title="Beat adding failed"
			message={error.message} />;
	}
	if (isMutating) {
		return <Spinner/>;
	}
	return (
		<BeatForm
			defaultValues={null}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}