import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";


import AttachTagForm from "./AttachTagForm";
import NavBar from "../../Navbar";

import { attachBeatTags, getBeat } from "../../../services/beatService";
import { getTags } from "../../../services/tagService";
import {
	Spinner
} from "@chakra-ui/react";
import { ErrorDisplay } from "../../ErrorDisplay";
export default function AttachTagEdit() {
	const params = useParams();
	return ( 
		<>
			<NavBar />
			<AttachTagEditData id={params.id} />
		</>
	);
}

function AttachTagEditData({ id }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading: isBeatLoading, isError: isBeatError, error: beatError, data: beatData } = useQuery(["beatData", id], () => getBeat(id));
	const { isLoading, isError, error, data } = useQuery(["tagsData"], () => getTags());
	const { mutateAsync, isLoading: isMutating, isError: isMutationError, error: mutationError } = useMutation(attachBeatTags, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("beatData");
			await queryClient.invalidateQueries("beatsData");

		}
	});
	const onFormSubmit = async (data) => {
		await mutateAsync({id, data});
		navigate("/beats");
	};
	const onFormCancel = () => {
		navigate("/beats");
	};

	if (isError) {
		return <ErrorDisplay
			title="Tag loading failed"
			message={error.message} />;
	}

	if (isBeatError) {
		return <ErrorDisplay
			title="Beat loading failed"
			message={beatError.message} />;
	}


	if (isMutationError) {
		return <ErrorDisplay
			title="Tag adding failed"
			message={mutationError.message} />;
	}

	if (isLoading || isMutating || isBeatLoading) {
		return <Spinner />;
	}
	return (
		<AttachTagForm
			tagData={data.data}
			beatData={beatData.data}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel}
		/>
	);
}