import {
	Box,
	Flex,
	HStack,
	Link,
	IconButton,
	Menu,
	MenuList,
	MenuItem,
	MenuDivider,
	useDisclosure,
	useColorModeValue,
	Stack,
} from "@chakra-ui/react";
import { AiOutlineMenu, AiFillCloseCircle } from "react-icons/ai";
import { ColorModeSwitcher } from "../ColorModeSwitcher";

const Links = [
	{name: "Dashboard", route: "/"},
	{name: "Beats", route: "/beats"},
	{name: "Tags", route: "/tags"},
	{name: "Files", route: "/files"},
	{name: "Prices", route: "/prices"}
];

const NavLink = ({ link }) => 
	(<Link
		px={2}
		py={1}
		rounded={"md"}
		_hover={{
			textDecoration: "none",
			bg: useColorModeValue("gray.200", "gray.700"),
		}}
		href={link.route}>
		{link.name}
	</Link>);

export default function NavBar() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
				<Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
					<IconButton
						size={"md"}
						icon={isOpen ? <AiFillCloseCircle /> : <AiOutlineMenu />}
						aria-label={"Open Menu"}
						display={{ md: "none" }}
						onClick={isOpen ? onClose : onOpen}
					/>
					<HStack spacing={8} alignItems={"center"}>
						<Box>Logo</Box>
						<HStack
							as={"nav"}
							spacing={4}
							display={{ base: "none", md: "flex" }}>
							{Links.map((link) => (
								<NavLink key={link.name} link={link}/>
							))}
						</HStack>
					</HStack>
					<Flex alignItems={"center"}>
						<Menu>
							<MenuList>
								<MenuItem>Link 1</MenuItem>
								<MenuItem>Link 2</MenuItem>
								<MenuDivider />
								<MenuItem>Link 3</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
					<ColorModeSwitcher/>
				</Flex>

				{isOpen ? (
					<Box pb={4} display={{ md: "none" }}>
						<Stack as={"nav"} spacing={4}>
							{Links.map((link) => (
								<NavLink key={link} link={link}></NavLink>
							))}
						</Stack>
					</Box>
				) : null}

			</Box>
		</>
	);
}