import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import TagForm from "./TagForm";
import NavBar from "../Navbar";

import { addTag } from "../../services/tagService";
import {
	Spinner
} from "@chakra-ui/react";
import { ErrorDisplay } from "../ErrorDisplay";
export default function TagAdd() {
	return (
		<>
			<NavBar />
			<TagEditData />
		</>
	);
}

function TagEditData() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { mutateAsync, isLoading: isMutating, isError, error} = useMutation(addTag, {
		onSuccess: async () => {
			await queryClient.invalidateQueries("tagsData");
		}
	});
	const onFormSubmit = async (formData) => {
		await mutateAsync({...formData });
		navigate("/tags");
	};
	const onFormCancel = () => {
		navigate("/tags");
	};
	if (isError) {
		return <ErrorDisplay
			title="Tag adding failed"
			message={error.message} />;
	}
	if (isMutating) {
		return <Spinner/>;
	}
	return (
		<TagForm
			defaultValues={null}
			onFormSubmit={onFormSubmit}
			onFormCancel={onFormCancel} />
	);
}